import {Button} from 'antd';
import React, {useState} from 'react';

import SwipeableViews from 'react-swipeable-views';
import {virtualize} from 'react-swipeable-views-utils';
import {mod} from 'react-swipeable-views-core';

import styled from 'styled-components';
import RightArrow from './RightArrow';
import LeftArrow from './LeftArrow';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
  top: 50px;
  width: 100%;
  background: rgb(11, 10, 10);
`

const SliderItems = styled.div`
  width: 100%;
  overflow-x: hidden;
  display: flex;
  background: rgb(11, 10, 10);
  align-items: center;
  position: relative;
  min-height: inherit
`

const SliderNavLeft = styled.div`
  background: #1890ff;
  border-color: #1890ff;
  border-radius: 8px;
  z-index: 3;

  :hover {
    background: white;
    border-color: white;
  }
`

const SliderNavRight = styled.div`
  background: #1890ff;
  border-color: #1890ff;
  border-radius: 8px;
  z-index: 3;

  :hover {
    background: white;
    border-color: white;
  }
`

const NavButtonStyles = {
    width: "68px",
    height: "68px",
    paddingTop: "13px",
    borderRadius: "8px",
    background: "inherit",
    borderColor: "inherit",
    zIndex: 5
}

const HideArrowDiv = styled.div`
  zIndex: 5;
  position: absolute;
  left: ${props => props.direction === "left" ? -15 + "px" : "none"};
  right: ${props => props.direction === "right" ? -15 + "px" : "none"};
  @media (max-width: 991px) {
    display: none;
  }
`

const TestDiv = styled.div`
  position: absolute;
  left: 520px;
  width: 56.5%;
  display: flex;
  align-items: center;
  z-index: 100;
  @media (max-width: 1570px) {
    width: 55%;
  }
  @media (max-width: 1500px) {
    width: 53%;
  }
  @media (max-width: 1440px) {
    width: 51%;
  }
  @media (max-width: 1370px) {
    width: 49.5%;
  }
  @media (max-width: 1320px) {
    width: 48.4%;
  }
  @media (max-width: 1275px) {
    width: 47%;
  }
  @media (max-width: 1220px) {
    width: 46%;
  }
  @media (max-width: 1198px) {
    left: 242px;
    width: 60%;
  }
  @media (max-width: 1160px) {
    width: 59%;
  }
  @media (max-width: 1110px) {
    width: 58%;
  }
  @media (max-width: 1110px) {
    width: 58%;
  }
  @media (max-width: 1080px) {
    width: 57%;
  }
  @media (max-width: 1030px) {
    width: 56%;
  }
`

const Item = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
`

const ItemImg = styled.div`
  width: 90% !important;
  display: flex;
  align-items: center;
  transition: opacity 0.5s;
  opacity: ${props => props.active ? 1 : 0.4};

  & img {
    width: 100% !important;
    object-fit: cover;
    height: 450px;
    margin: 0 auto;
    border-radius: 14px;
    @media (max-width: 700px) {
      height: 310px;
    }
    @media (max-width: 560px) {
      height: 290px;
    }
    @media (max-width: 380px) {
      height: 220px;
    }
  }
`

const Item2 = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 900px;
  border-radius: 14px;
`

const ItemImg2 = styled.div`
  width: 83% !important;
  display: flex;
  align-items: center;
  transition: transform 0.5s, opacity 0.5s;
  opacity: ${props => props.active ? 1 : 0.4};
  transform: scale(${props => props.active ? 1.2 : 1});

  & img {
    width: 100% !important;
    height: 520px;
    object-fit: cover;
    max-height: 630px;
    margin: 0 auto;
    border-radius: 14px;
    @media (max-width: 1600px) {
      height: 520px;
    }
    @media (max-width: 1500px) {
      height: 440px;
    }
    @media (max-width: 1400px) {
      height: 410px;
    }
    @media (max-width: 1300px) {
      height: 380px;
    }
    @media (max-width: 1200px) {
      height: 430px;
    }
    @media (max-width: 1100px) {
      height: 440px;
    }
    @media (max-width: 1000px) {
      height: 370px;
    }

  }
`

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

function slideRenderer(params) {
    const {index, key, items, selectedItem} = params;

    return (
        <Item key={key}>
            <ItemImg active={selectedItem === index}>
                <img className="locked" src={items[mod(index, items.length)].localFile.publicURL}
                     alt={"carousel-nice"}/>
            </ItemImg>
        </Item>
    )
}


function slideRenderer2(params) {
    const {index, key, items, selectedItem} = params;
    return (
        <Item2 key={key}>
            <ItemImg2 active={selectedItem === index}>
                <img className="locked" src={items[mod(index, items.length)].localFile.publicURL}
                     alt={"carousel-niced"}/>
            </ItemImg2>
        </Item2>
    )
}

const springConfig = {
    duration: "0.8s",
    easeFunction: "cubic-bezier(0.1, 0.35, 0.2, 1)",
    delay: "0.05s",
};

const Carousel = ({items}) => {
    const screens = useBreakpoint();

    const [selectedItem, setSelectedItem] = useState(0);
    const [hoverArrow, setHoverArrow] = useState(null);

    const handleMouseEnter = direction => {
        setHoverArrow(direction);
    }

    const handleMouseLeave = () => setHoverArrow(null);

    return (
        <SliderContainer>
            {
                !screens.lg ?
                    <VirtualizeSwipeableViews springConfig={springConfig} onChangeIndex={setSelectedItem}
                                              index={selectedItem} style={{padding: screens.xs ? "0 30px" : "0 80px"}}
                                              slideRenderer={(e) => slideRenderer({...e, items, selectedItem})}/>
                    :
                    <SliderItems>

                        <VirtualizeSwipeableViews containerStyle={{width: "90%"}} onChangeIndex={setSelectedItem}
                                                  index={selectedItem} springConfig={springConfig}
                                                  style={{padding: screens.xl ? "0 80px 0 530px" : "0 150px 0 250px"}}
                                                  slideRenderer={(e) => slideRenderer2({...e, items, selectedItem})}/>

                        <TestDiv>
                            <HideArrowDiv direction="left">
                                <SliderNavLeft onClick={() => setSelectedItem(selectedItem - 1)}>
                                    <Button type="primary" onMouseEnter={() => handleMouseEnter("left")}
                                            onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                        <LeftArrow color={hoverArrow === "left" && "#4285F4"}/>
                                    </Button>
                                </SliderNavLeft>
                            </HideArrowDiv>

                            <HideArrowDiv direction="right">
                                <SliderNavRight onClick={() => setSelectedItem(selectedItem + 1)}>
                                    <Button type="primary" onMouseEnter={() => handleMouseEnter("right")}
                                            onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                        <RightArrow color={hoverArrow === "right" && "#4285F4"}/>
                                    </Button>
                                </SliderNavRight>
                            </HideArrowDiv>
                        </TestDiv>
                    </SliderItems>
            }
        </SliderContainer>
    )
}

export default Carousel;
