import React from 'react'
import styled from "styled-components";
import Video from "./video";
import DescriptionVideo from "./descriptionvideo";
import PlusComponent from "../../ui/plusComponent";


const Content = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

`;


const ContainerDescriptionVideo = ({description, url, preview}) => {
    return (
        <>
            <div style={{marginBottom: 76}}>
                <PlusComponent text="описание проекта"/>
            </div>
            <Content>
                <DescriptionVideo description={description}/>
                <Video url={url} preview={preview}/>
            </Content>
        </>
    )
}

export default ContainerDescriptionVideo
