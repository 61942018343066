import React, {useState} from "react";
import {Button} from "antd";
import styled from "styled-components";
import Play from '../../assets/Play.svg'
import Preview from '../../images/Preview.jpg'

const VideoTag = styled.iframe`
  width: 100%;
  border: 3px solid black;
  border-radius: 8px;
  height: 100%;
  background-color: black;
`;


const Container = styled.div`
  position: relative;
  width: 620px;
  height: 400px;
  border-radius: 8px;
  @media (max-width: 1000px) {
    margin-top: 90px;
    margin-left: -20px;
  }
  @media (max-width: 768px) {
    width: 520px;
    height: 300px;
  }
  @media (max-width: 568px) {
    width: 380px;
    height: 250px;
  }
  @media (max-width: 419px) {
    margin-top: 50px;
    width: 300px;
    height: 200px;
  }
  @media (max-width: 368px) {
    width: 250px;
    height: 150px;
  }
  @media (max-width: 289px) {
    margin-left: -60px;
    width: 200px;
    height: 130px;
  }


`;

const PlayPause = styled(Button)`
  margin: 50px auto;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  :hover {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    & svg > path {
      fill: #4285F4;
    }
  }
  @media (max-width: 968px) {
    width: 60px;
    height: 60px;
  }
  
  @media (max-width: 568px) {
    margin: 30px auto;
    width: 50px;
    height: 50px;
  }
  @media (max-width: 419px) {
    width: 40px;
    height: 40px;
    & svg {
      width: 13px;
    }
  }
  @media (max-width: 368px) {
    margin: 20px auto;
  }
`;

const NameVideo = styled.div`
  margin-top: 60px;
  color: white;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  @media (max-width: 968px) {
    font-size: 26px;
    line-height: 35px;
  }
  @media (max-width: 768px) {
    font-size: 25px;
    line-height: 25px;
  }
  @media (max-width: 419px) {
    margin-top: 40px;
  }
  @media (max-width: 368px) {
    margin-top: 20px;
    font-size: 18px;
    line-height: 25px;
  }
`;


const Background = styled.div`
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 8px;
  height: 100%;
  background-image: url(${props => props.image || Preview});
  background-size: cover;
  opacity: 0.5;
`;

const Video = ({url, preview}) => {
    const [play, setPlay] = useState(false);
    const link = url.split('https://youtu.be/');

    return (
        <Container>
            {!play ?
                <>
                    <Background image={preview}></Background>
                    <NameVideo>Смотреть видео</NameVideo>
                    <PlayPause type="primary" shape="circle" onClick={() => {
                        setPlay(true);
                    }}
                    >
                        <Play style={{marginLeft: 5}}/>
                    </PlayPause>

                </> :

                <div style={{width: '100%', height: '100%'}}>
                    <VideoTag frameBorder="0" src={play === true ? "https://www.youtube.com/embed/" + link[1] + '?autoplay=1' : ''}  allowFullScreen="allowFullScreen"/>
                </div>
            }

        </Container>
    )

}

export default Video;
