import React from 'react'
import styled from "styled-components";

const Content = styled.div`
  color: white;
  max-width: 600px;
  margin-right: 50px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;
  @media (max-width: 900px) {
    font-size: 14px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 22px;
    max-width: 100%;
  }
`;


const DescriptionVideo = ({description}) => {

    return (
        <div>
            <Content>
                {description}
            </Content>
        </div>
    )
}

export default DescriptionVideo
