import React from "react";
import styled from "styled-components";
import "../layout.css";
import PlusComponent from "../../ui/plusComponent";
import Link from "gatsby-link";

const Container = styled.div`
  text-align: center;
  margin-top: 75px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 968px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 568px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ContainerCategory = styled.div`
  margin-bottom: 127px;
  display: flex;
  align-items: center;
  flex-direction: ${props => props.vertical === true ? "column" : "row"};
  flex-wrap: ${props => props.vertical === true ? "nowrap" : "wrap"}px;
  @media (max-width: 568px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px
  }
`;

const ImgText = styled.div`
  max-width: 289px;
  font-size: 14px;
  line-height: 17px;
  margin-left: ${props => props.vertical === true ? 0 : 25}px;
  margin-top: ${props => props.vertical === true ? 25 : 0}px;
  color: ${props => props.activeText !== true ? '#ffffffff' : '#333131'};
  text-transform: ${props => props.vertical === true ? "none" : "uppercase"};
  @media (max-width: 600px) {
    margin-top: 25px;
    margin-left: 0;
  }
`;


const Category = (props) => {
    return (
        <>
            <PlusComponent text={props.vertical === true ? "Что входит" : "Установленные системы"}/>
            <Container>
                <GridContainer>
                    {props.data.map((item, index) => {
                        return (
                            !props.disable ?
                                <Link to={'/services/' + item.url}> <ContainerCategory vertical={props.vertical}
                                                                                       key={index + "key"}>
                                    {item.img}
                                    <ImgText activeText={item.active} vertical={props.vertical}>{item.name}</ImgText>
                                </ContainerCategory>

                                </Link> :
                                <ContainerCategory vertical={props.vertical} key={index + " c"}>
                                    {item.img}
                                    <ImgText activeText={item.active} vertical={props.vertical}>{item.name}</ImgText>
                                </ContainerCategory>
                        );
                    })}
                </GridContainer>
            </Container>
        </>

    );
};

export default Category;
