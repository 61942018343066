import React, {useState} from 'react';
import {ReactSVG} from 'react-svg'
import {graphql} from "gatsby";
import Header from "../components/Header/header";
import {ContainerCarousel, ContainerL} from "../ui/containers";
import FormBlock from "../components/Form";
import Footer from "../components/Footer/footer";
import Heading from "../ui/heading";
import Carousel from "../components/Carousel/Carousel.jsx"
import ContainerDescriptionVideo from "../components/DescriptionVideo";
import Category from "../components/Category";
import Card from "../components/Cards";
import PlusComponent from "../ui/plusComponent";
import styled from "styled-components";
import Modal from "../ui/modal";
import '../components/layout.css';
import Seo from "../components/seo";


const Svg = styled(ReactSVG)`
  & > div > svg > path {
    fill: ${props => props.color ? "#333131" : "#4285F4"}
  }
`;


const Project = (props) => {
    const {pageContext} = props;
    const [openModal, setOpenModal] = useState(false);
    const {video, carousel, services, carouselSchema, cards, allStrapiCard} = pageContext;
    const servicesAll = props.data.allStrapiService.nodes;
    const Marquiz = typeof window !== "undefined" && window.Marquiz;
    const svgData = servicesAll.map(item => {
        const a = Boolean(services.find(item2 => item2.id === item.strapiId));
        return {
            name: item.title,
            img: <Svg color={!a} src={item.iconSvg.localFile.publicURL}/>,
            active: !a,
            url: item.id
        }
    })

    const data = cards.map(item => {
        return ({
            title: item.title,
            text: item.description,
            imgUrl: item.image.localFile,
            cards: allStrapiCard.nodes.filter(card =>
                item.id === card.strapiId
            )
        })
    })

    const toggleOpenModal = () => {
        if (!openModal) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
        setOpenModal(!openModal);
    }

    return (
        <>
            <Seo title={pageContext.seo_title}
                 description={pageContext.seo_description}
                 keywords={pageContext.seo_keywords.split(',')}
            />
                <>
                    <Header/>
                    <Modal openModal={openModal} toggleOpenModal={toggleOpenModal}/>
                    <ContainerL style={{margin: "0 auto 100px auto"}}>
                        <Otstup/>
                        <Heading
                            title={pageContext.title}
                            description={pageContext.description}
                            button
                            toggleOpenModal={() => Marquiz.showModal('60dc9d431568cf003e72e6ca')}
                            background={`№${pageContext.serialNumber}`}
                        />
                    </ContainerL>

                    <ContainerCarousel style={{margin: "100px auto"}}>
                        <Carousel items={carousel}/>
                    </ContainerCarousel>

                    <ContainerL style={{margin: "100px auto"}}>
                        <ContainerDescriptionVideo
                            preview={video.preview_video.localFile.publicURL}
                            description={video.description}
                            url={video.link}
                        />
                    </ContainerL>

                    <ContainerL style={{margin: "100px auto"}}>
                        <Category data={svgData}/>
                    </ContainerL>
                    <ContainerCarousel style={{margin: "100px auto"}}>
                        <Carousel items={carouselSchema}/>
                    </ContainerCarousel>
                    <ContainerL style={{margin: "172px auto"}}>
                        <PlusComponent text="установленные оборудования"/>
                    </ContainerL>

                    <ContainerL style={{margin: "120px auto"}}>
                        {data.map((item, index) => (
                            <div key={index}>
                                <Card
                                    plus={false}
                                    toggleOpenModal={toggleOpenModal}
                                    isreversed={index % 2 !== 1}
                                    data={item}
                                />
                            </div>
                        ))}
                    </ContainerL>
                        <ContainerL style={{marginBottom: 120}}>
                            <FormBlock/>
                        </ContainerL>
                    <Footer toggleOpenModal={toggleOpenModal}/>
                </>
        </>
    );
};

export default Project;

export const query = graphql`
    query ProjectQuery {
        allStrapiService(sort: {fields: serialNumber})  {
            nodes {
                id
                iconSvg {
                    localFile {
                        publicURL
                    }
                }
                strapiId
                title
            }
        }
    }

`


const Otstup = styled.div`
  margin-top: 80px;
  @media (max-width: 568px) {
    margin-top: 0px;
  }
`;

